var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "parking-block" },
    [
      _c("div", { staticClass: "parking-block__header" }, [
        _c("picture", [
          _c("source", {
            attrs: {
              srcset: require("@/assets/images/parking/header.png"),
              media: "(min-width: 1366px)",
            },
          }),
          _c("source", {
            attrs: {
              srcset: require("@/assets/images/parking/header-t.jpg"),
              media: "(min-width: 640px)",
            },
          }),
          _c("img", {
            staticClass: "parking-block__banner",
            attrs: { src: require("@/assets/images/parking/header-m.jpg") },
          }),
        ]),
      ]),
      _vm.parkingLoaded
        ? _c(
            "content-wrapper-block",
            { staticClass: "parking-block__wrapper" },
            [
              _c("div", { staticClass: "parking-block__container" }, [
                _c("div", { staticClass: "parking-block__title" }, [
                  _vm._v(" Доступные места "),
                  _c("span", { staticClass: "parking-block__count" }, [
                    _vm._v("(" + _vm._s(_vm.countItems) + ")"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "parking-block__tabs parking-tabs" },
                  [
                    _c(
                      "ui-tab-bar",
                      {
                        staticClass: "parking-tabs__bar",
                        model: {
                          value: _vm.current,
                          callback: function ($$v) {
                            _vm.current = $$v
                          },
                          expression: "current",
                        },
                      },
                      _vm._l(_vm.parkingFloors, function (floor, index) {
                        return _c(
                          "ui-tab-bar-tab",
                          { key: index, attrs: { value: floor } },
                          [_vm._v(" " + _vm._s(floor) + " этаж ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "parking-block__container" }, [
                _c("div", { staticClass: "parking-block__plan" }, [
                  _c("img", {
                    staticClass: "parking-block__background",
                    attrs: { src: _vm.floorImage },
                  }),
                  _c(
                    "svg",
                    {
                      staticClass: "parking-viewer",
                      attrs: {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: _vm.svgViewBox,
                        preserveAspectRatio: "xMinYMin",
                      },
                    },
                    [
                      _vm._l(_vm.parkingCurrentPaths, function (place, index) {
                        return _c(
                          "text",
                          {
                            key: "_" + index,
                            attrs: {
                              x: place.center[0],
                              y: place.center[1] + 5,
                              "text-anchor": "middle",
                              fill: "black",
                              "font-weight": "500",
                              "font-size": "15",
                            },
                          },
                          [_vm._v(" " + _vm._s(place.number) + " ")]
                        )
                      }),
                      _vm._l(_vm.parkingCurrentPaths, function (place, index) {
                        return _c("polygon", {
                          key: index,
                          staticClass: "parking-viewer__item",
                          attrs: {
                            points: place.path,
                            fill: "transparent",
                            stroke: "#484848",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showParkingModal(place.number)
                            },
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "parking-block__content plans-content" },
                  [
                    _c(
                      "div",
                      { staticClass: "plans-content__table" },
                      [
                        _c(
                          "ui-table",
                          {
                            staticClass:
                              "plans-content__aparts apart-type-table",
                            attrs: { data: _vm.parkingItems },
                            on: {
                              "click-row": ({ number }) =>
                                _vm.showParkingModal(number),
                            },
                          },
                          [
                            _c("ui-table-column", {
                              attrs: { label: "Этаж", prop: "_floor_number" },
                            }),
                            _c("ui-table-column", {
                              attrs: { label: "Номер", prop: "number" },
                            }),
                            _c(
                              "ui-table-column",
                              {
                                attrs: { label: "площадь, м²", prop: "layout" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (layout) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(layout.row.layout.area) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2717082238
                                ),
                              },
                              [
                                _c("ui-table-column", {
                                  attrs: { label: " ", prop: "layout" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "ui-table-column",
                              {
                                attrs: { label: "Цена, ₽", prop: "cost" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (cost) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                cost.row.cost.toLocaleString(
                                                  "ru"
                                                )
                                              ) +
                                              " "
                                          ),
                                          cost.row.action
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "plans-content__action",
                                                },
                                                [_vm._v(" % ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1754599593
                                ),
                              },
                              [
                                _c("ui-table-column", {
                                  attrs: { label: " ", prop: "cost" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "ui-table-column",
                              { staticClass: "plans-content__truck" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/icons/vector.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c("ui-table-column", [_vm._v("Забронировать")]),
                          ],
                          1
                        ),
                        _vm.page < _vm.numPages
                          ? _c(
                              "ui-button",
                              {
                                attrs: { outline: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.next(+1)
                                  },
                                },
                              },
                              [_vm._v(" Показать больше ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }